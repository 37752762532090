<template>
	<div class="main">
         <TheTopbar/>
         <slot />
	<div class="main-container">
    <div class="d-flex">
      
      <div class="content-wrapper">
        <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 class="text-white">AUDIT TRAIL</h5>
                
            </div>
        <div class="modal-body">
          
              <br>
              <div class="market-content">
                
               
                <div class="market-content">
                  <h5 class="text-white">Admin Logs</h5>
                  <table class="table">
                    <thead>
                      <tr>
                          <th>Alert ID</th>
                          <th>Date</th>
                          <th>Description</th>
                          <th>IP address</th>
                          <th>User ID</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="alert in paginatedData" :key="alert.id">
                        <td>{{ alert.auditTrailId }}</td>
                        <td>{{ alert.createDate }}</td>
                        <td>{{ alert.description }}</td>
                        <td>{{ alert.ipAddress }}</td>
                        <td>{{ alert.userId }}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  
                  </div>
                  <div>
                    <button @click="prevPage" :disabled="currentPage === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPage }} </span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="btn btn-light">Next</button>
                  </div>
              
        </div>
        
        <hr>
       
      </div>
    </div>
    </div>
    <Resolve v-if="showAction" @close="resolve()"></Resolve>
	</div>
</template>
        
<script>
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
  import ViewInfo from '../components/modal/Documents/ViewInfo.vue'
  

  export default {
      name: 'PendingAlerts',
      components: {
        TheTopbar,
        Header,
        ViewInfo
      },
      
       data: function() {
      return { 
        isApproved: false,
        showAction: false,
        adminLogs: [],
        entityId:'',
        itemsPerPage: 20,
        currentPage: 1

      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        totalPages() {
          return Math.ceil(this.adminLogs.length / this.itemsPerPage);
        },
        paginatedData() {
          const start = (this.currentPage - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.adminLogs.slice(start, end);
        },
      
     
      },
      methods:{
        getAdminLogs(){
                const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/audittrail/list',
                    };

                  this.axios(options)
                  .then((response) => { 
                  console.log(response.data)
                 this.adminLogs = response.data

                 this.adminLogs.forEach(element => {
                  this.entityId = element.entityId
                 });
                
                })
        },
        goToProfile(){
          window.location.href="/#/customer-activity" + this.entityId
        },
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
          }
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
          }
        },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
            this.axios(options)
              .then((response) => {
             
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getAdminLogs();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  
    .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #FFFFFF; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
   
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    color: black !important;
    
  }
 .search{
  color: black !important;
 }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-color: #b47e27 !important;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
